import { createStore } from 'vuex'

export default new createStore({
  state: {
    loginUser: {},
    loginAdminUser: {},
    adminUsers: [],
    flashMessage: {
      color: 'success',
      message: null
    },
    signUpData: {
      name: null,
      furigana: null,
      email: null,
      tel: null,
      company_name: null,
      note: null
    }
  },
  getters: {
  },
  mutations: {
    setUser(state, val) {
      state.loginUser = val
    },
    setAdminUser(state, val) {
      state.loginAdminUser = val
    },
    clearAdminUser(state) {
      state.loginAdminUser = {}
    },
    updateUser(state, val) {
      state.loginUser.name = val.name
      state.loginUser.furigana = val.furigana
      state.loginUser.tel = val.tel
      state.loginUser.email = val.email
    },
    setAdminUsers(state, val) {
      state.adminUsers = val
    },
    setFlashSuccess(context, payload) {
      console.log(context)
      context.flashMessage.color = 'success'
      context.flashMessage.message = payload
    },
    setFlashError(context, payload) {
      console.log(context)
      context.flashMessage.color = 'error'
      context.flashMessage.message = payload
    },
    setSignUpName(state, val) {
      state.signUpData.name = val
    },
    setSignUpFurigana(state, val) {
      state.signUpData.furigana = val
    },
    setSignUpEmail(state, val) {
      state.signUpData.email = val
    },
    setSignUpTEL(state, val) {
      state.signUpData.tel = val
    },
    setSignUpCompanyName(state, val) {
      state.signUpData.company_name = val
    },
    setSignUpNote(state, val) {
      state.signUpData.note = val
    },
    clearSignUpData(state) {
      state.signUpData = {}
    }
  },
  actions: {
    setUser(context, val) {
      context.commit('setUser', val)
    },
    setAdminUser(context, val) {
      context.commit('setAdminUser', val)
    },
    clearAdminUser(context) {
      context.commit('clearAdminUser')
    },
    updateUser(context, val) {
      context.commit('updateUser', val)
    },
    setAdminUsers(context, val) {
      context.commit('setAdminUsers', val)
    },
    setSignUpName(context, val) {
      context.commit('setSignUpName', val)
    },
    setSignUpFurigana(context, val) {
      context.commit('setSignUpFurigana', val)
    },
    setSignUpEmail(context, val) {
      context.commit('setSignUpEmail', val)
    },
    setSignUpTEL(context, val) {
      context.commit('setSignUpTEL', val)
    },
    setSignUpCompanyName(context, val) {
      context.commit('setSignUpCompanyName', val)
    },
    setSignUpNote(context, val) {
      context.commit('setSignUpNote', val)
    },
    clearSignUpData(context) {
      context.commit('clearSignUpData')
    }
  },
  modules: {
  },
})
