import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import '@/assets/css/reset.css'
import '@/assets/css/common.css'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'

// vue-good-table-next
import VueGoodTablePlugin from 'vue-good-table-next'
import 'vue-good-table-next/dist/vue-good-table-next.css'
import '@/assets/css/custom-vgt.css'

// vue-json-csv
import JsonCSV from 'vue-json-csv'

// pagination
import Paginate from "vuejs-paginate-next"
import '@/assets/css/pagination.css'

// 自作コンポーネント
import AdminMenu from '@/components/parts/AdminMenu'
import Header from '@/components/parts/Header'

// ドロワーメニュー
import VueSidePanel from 'vue3-side-panel'
import 'vue3-side-panel/dist/vue3-side-panel.css'
import '@/assets/css/custom-vsp.css'

// スムーススクロール
import VueScrollTo from 'vue-scrollto';

// 動画プレイヤー
import VueVideoPlayer from 'vue-video-player';
import 'video.js/dist/video-js.css';

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
  },
})

const app = createApp(App)

app.use(vuetify)
app.use(router)
app.use(store)
app.use(VueAxios, axios)
app.use(VueGoodTablePlugin)
app.component('downloadCsv', JsonCSV)
app.component('AdminMenu', AdminMenu)
app.component('Header', Header)
app.use(Paginate)
app.use(VueSidePanel)
app.use(VueScrollTo, { offset: -120 })
app.use(VueVideoPlayer)
app.mount('#app')
