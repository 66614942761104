import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import axios from 'axios'

const routes = [
  {
    path: '/',
    redirect: '/line/signup'
  },
  {
    path: '/line/signup',
    name: 'line:signup',
    component: () => import('@/components/views/Line/Signup.vue'),
    meta: {
      title: '初回登録'
    },
  },
  {
    path: '/line/signup/thanks',
    name: 'line:thanks',
    component: () => import('@/components/views/Line/Thanks.vue'),
    meta: {
      title: '初回登録完了'
    }
  },
  {
    path: '/line/mypage/info',
    name: 'line:mypage',
    component: () => import('@/components/views/Line/Mypage.vue'),
    meta: {
      title: 'マイページ',
      requiresUserAuth: true
    }
  },
  {
    path: '/line/mypage/faq',
    name: 'line:faq',
    component: () => import('@/components/views/Line/FAQ.vue'),
    meta: {
      title: 'Q&A',
    }
  },
  {
    path: '/line/privacy',
    name: 'line:privacy',
    component: () => import('@/components/views/Line/PrivacyPolicy.vue'),
    meta: {
      title: 'プライバシーポリシー',
    }
  },
  {
    path: '/line/mypage/users/edit',
    name: 'line:users_edit',
    component: () => import('@/components/views/Line/UsersEdit.vue'),
    meta: {
      title: '会員情報変更',
      requiresUserAuth: true
    }
  },
  {
    path: '/line/mypage/properties',
    name: 'line:properties_list',
    component: () => import('@/components/views/Line/Properties.vue'),
    meta: {
      title: '投稿物件一覧',
      requiresUserAuth: true
    }
  },
  {
    path: '/line/mypage/properties/:id',
    name: 'line:properties_detail',
    component: () => import('@/components/views/Line/PropertiesDetail.vue'),
    meta: {
      title: '投稿物件詳細',
      requiresUserAuth: true
    }
  },

  {
    path: '/admin',
    name: 'admin:top',
    component: () => import('@/components/views/Admin/Admin.vue'),
    meta: {
      title: '管理TOP',
      requiresAdminAuth: true
    }
  },
  {
    path: '/admin/login',
    name: 'admin:login',
    component: () => import('@/components/views/Admin/Login.vue'),
    meta: {
      title: '管理者ログイン',
    }
  },
  {
    path: '/admin/password/reset',
    name: 'admin:password_reset',
    component: () => import('@/components/views/Admin/PasswordReset.vue'),
    meta: {
      title: '管理者 パスワード再設定',
    }
  },
  {
    path: '/admin/password/reset/done',
    name: 'admin:password_reset_done',
    component: () => import('@/components/views/Admin/PasswordResetDone.vue'),
    meta: {
      title: '管理者 パスワード再設定 メール送信完了',
    }
  },
  {
    path: '/admin/password-reset/:token/:email',
    name: 'admin:password_reset_action',
    component: () => import('@/components/views/Admin/PasswordResetAction.vue'),
    meta: {
      title: '管理者 パスワード再設定',
    }
  },
  {
    path: '/admin/properties/:id',
    name: 'admin:properties_detail',
    component: () => import('@/components/views/Admin/PropertiesDetail.vue'),
    meta: {
      title: '物件詳細',
      requiresAdminAuth: true
    }
  },
  {
    path: '/admin/properties/:id/edit',
    name: 'admin:properties_edit',
    component: () => import('@/components/views/Admin/PropertiesEdit.vue'),
    meta: {
      title: '物件編集',
      requiresAdminAuth: true
    }
  },
  {
    path: '/admin/account',
    name: 'admin:account',
    component: () => import('@/components/views/Admin/Account.vue'),
    meta: {
      title: '管理者アカウント 一覧',
      requiresAdminAuth: true
    }
  },
  {
    path: '/admin/account/add',
    name: 'admin:account_add',
    component: () => import('@/components/views/Admin/AccountAdd.vue'),
    meta: {
      title: '管理者アカウント 追加',
      requiresAdminAuth: true
    }
  },
  {
    path: '/admin/account/:id/edit',
    name: 'admin:account_edit',
    component: () => import('@/components/views/Admin/AccountEdit.vue'),
    meta: {
      title: '管理者アカウント 編集',
      requiresAdminAuth: true
    }
  },

  {
    path: '/:catchAll(.*)',
    name: 'all:not_found',
    component: () => import('@/components/views/Errors/NotFound.vue'),
    meta: {
      title: 'ページが見つかりません',
    }
  },
  {
    path: '/line/:catchAll(.*)',
    name: 'line:not_found',
    component: () => import('@/components/views/Errors/LineNotFound.vue'),
    meta: {
      title: 'ページが見つかりません',
    }
  },
  {
    path: '/admin/:catchAll(.*)',
    name: 'admin:not_found',
    component: () => import('@/components/views/Errors/AdminNotFound.vue'),
    meta: {
      title: 'ページが見つかりません',
    }
  },
  {
    path: '/error',
    name: 'all:error',
    component: () => import('@/components/views/Errors/Error.vue'),
    meta: {
      title: 'エラーが発生しました',
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        top: 0
      }
    }
  },
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAdminAuth)) { // 管理者の認証チェック
    // storeにログイン情報が保持されているか
    if (!Object.keys(store.state.loginAdminUser).length) {
      // 保持されていない場合、セッションが残っているか確認
      axios.get(`${process.env.VUE_APP_API_BASE_URL}/admin/me`)
        .then(res => {
          store.commit('setAdminUser', res.data)
          next()
        })
        .catch(() => {
          next({
            name: 'admin:login'
          })
        })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.requiresUserAuth)) { // 利用者の認証チェック
    // storeにログイン情報が保持されているか
    if (!Object.keys(store.state.loginUser).length) {
      // 未ログインなので、Lineログインの処理
      const url = `${process.env.VUE_APP_BASE_URL}/sanctum/csrf-cookie`
      const fetchLineIdUrl = `${process.env.VUE_APP_API_BASE_URL}/get-id`
      const loginUrl = `${process.env.VUE_APP_API_BASE_URL}/login`
      const paramCode = to.query.code
      const redirectUrl = to.query.liffRedirectUri
      if (paramCode !== undefined && redirectUrl !== undefined) {
        axios.get(url)
          .then(() => {
            // トークンからユーザーIDを取得
            const formData = new FormData()
            formData.append('code', paramCode)
            formData.append('redirect_url', redirectUrl)

            axios.post(fetchLineIdUrl, formData)
              .then(res => {
                const lineId = res.data.data
                const formData = new FormData()
                // XXX: emailとpasswordは、ログイン処理には使われてないが、fortifyのコア部分にバリデーションが書かれている為エラー回避の送信
                formData.append('email', 'aaaaaaaaaaaaa@phonogram.co.jp')
                formData.append('password', 'fdfdsfd453434fdfd')
                formData.append('userLineId', lineId)

                // ログイン処理
                axios.post(loginUrl, formData)
                  .then(res => {
                    store.commit('setUser', res.data)
                    next()
                  })
                  .catch(err => {
                    // 失敗すれば、閉じる案内？
                    console.log(err)
                    next({
                      name: 'all:error'
                    })
                  })
              })
              .catch(err => {
                // 失敗すれば、閉じる案内？
                console.log(err)
                next({
                  name: 'all:error'
                })
              })
          })
      } else if (to.query.line_id !== undefined) {
        // クエリパラメータに直接 LINE ID を指定している場合もあるので再度ログインを試す
        const formData = new FormData()
        formData.append('email', 'aaaaaaaaaaaaa@phonogram.co.jp')
        formData.append('password', 'fdfdsfd453434fdfd')
        formData.append('userLineId', to.query.line_id)

        axios.post(loginUrl, formData)
          .then(res => {
            store.commit('setUser', res.data)
            next()
          })
          .catch(err => {
            // 失敗すれば、閉じる案内？
            console.log(err)
            next({
              name: 'all:error'
            })
          })
      } else {
        // LINE内ブラウザ外からのアクセス
        // セッションが残っているか確認
        axios.get(`${process.env.VUE_APP_API_BASE_URL}/me`)
          .then(res => {
            store.commit('setUser', res.data)
            next()
          })
          .catch(err => {
            // ダメです
            console.log(err)
            next({
              name: 'all:error',
              props: { err: err.response.data }
            })
          })
      }
    } else {
      next()
    }
  } else {
    next()
  }
})

router.afterEach((to) => {
  document.title = 'KJEX | ' + to.meta.title || 'KJEX 不動産仲介業務システム'
})

export default router;
