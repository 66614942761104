<template>
  <div class="line__header">
    <div class="name-logo__wrap">
      <p class="header__user-name">{{ userName }}様</p>
      <div class="header-logo__wrap">
        <p>×</p>
        <img src="@/assets/img/logo-white.png" alt="">
      </div>
    </div>
    <p class="page-title">{{ title }}</p>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  props: ['title'],
  setup() {
    const store = useStore()
    const userName = computed(() => {
      return store.state.loginUser.name
    })

    return {
      userName
    }
  },
}
</script>

<style scoped>
.line__header {
  width: 100%;
  max-width: 390px;
  max-height: 110px;
  background-color: #1C492A;
  text-align: center;
  border-radius: 0 0 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  left: 0;
  right: 0;
  z-index: 10000;
  margin: auto;
}

.name-logo__wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 95%;
}

.header-logo__wrap {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.header-logo__wrap p {
  margin-right: 10px;
}

.header-logo__wrap img {
  width: 60px;
}

.page-title {
  font-size: 25px;
  font-weight: 600;
  color: #fff;
  margin-top: 5px;
}
</style>
