<template>
  <VueSidePanel v-model="isOpened" lock-scroll width="70%">
    <div class="sidePanel-menu">
      <p>ログイン中<br />{{ userName }}</p>
      <p class="sidePanel__text" @click="routeAdminTop">
        <v-icon>mdi-home</v-icon>物件管理
      </p>
      <p class="sidePanel__text" @click="routeAdminAccount">
        <v-icon>mdi-account</v-icon>アカウント管理
      </p>
      <p class="sidePanel__text" @click="downloadUserCSV">
        <v-icon>mdi-download</v-icon>登録ユーザーCSV出力
      </p>
      <p class="sidePanel__text sidePanel-logout" @click="logout">
        <v-icon>mdi-logout</v-icon>ログアウト
      </p>
      <v-divider></v-divider>
      <p class="sidePanel__text sidePanel-num">利用者数<br />
        <span>{{ total_users }}人（+{{ total_users_change }}人）</span>
      </p>
      <p class="sidePanel__text sidePanel-num">物件登録数<br />
        {{ total_properties }}件（+{{ total_properties_change }}件）
      </p>
    </div>
  </VueSidePanel>

  <div class="admin-menu__wrap">
    <div class="admin-menu__logo-wrap">
      <img class="admin-menu__logo" src="@/assets/img/logo-white.png" @click="routeAdminTop">
    </div>
    <div class="admin-menu-overflow">
      <div class="admin-menu__items-wrap">
        <p class="admin-menu__userName">ログイン中<br />{{ userName }}</p>
      </div>
      <div class="menu__wrap">
        <div class="admin-menu__items-wrap">
          <p class="admin-menu__text" @click="routeAdminTop">
            <v-icon>mdi-home</v-icon>物件管理
          </p>
        </div>
        <div class="admin-menu__items-wrap">
          <p class="admin-menu__text" @click="routeAdminAccount">
            <v-icon>mdi-account</v-icon>アカウント管理
          </p>
        </div>
        <div class="admin-menu__items-wrap">
          <p class="admin-menu__text" @click="downloadUserCSV">
            <v-icon>mdi-download</v-icon>登録ユーザーCSV出力
          </p>
        </div>
        <div class="admin-menu__items-wrap">
          <p class="admin-menu__text" @click="logout">
            <v-icon>mdi-logout</v-icon>ログアウト
          </p>
        </div>
      </div>
      <div class="menu-num__wrap">
        <div class="admin-menu__items-wrap admin-menu__num-wrap">
          <p class="admin-menu__num">利用者数<br />
            <span>{{ total_users }}人<br />（前日比 +{{ total_users_change }}人）</span>
          </p>
        </div>
        <div class="admin-menu__items-wrap admin-menu__num-wrap">
          <p class="admin-menu__num">物件登録数<br />
            <span>{{ total_properties }}件<br />（前日比 +{{ total_properties_change }}件）</span>
          </p>
        </div>
      </div>

      <div class="admin-menu__icon-wrap"><v-icon icon="mdi-menu" @click="isOpened = !isOpened"></v-icon></div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import axios from 'axios'

export default {
  props: ['name'],
  data: () => ({
    isOpened: false,
    total_users: null,
    total_users_change: null,
    total_properties: null,
    total_properties_change: null,
  }),
  methods: {
    routeAdminTop() {
      this.$router.push({ name: 'admin:top' })
    },
    routeAdminAccount() {
      this.$router.push({ name: 'admin:account' })
    },
    downloadUserCSV() {
      axios.get(`${process.env.VUE_APP_API_BASE_URL}/admin/user/export`)
        .then(response => response.data)
        .then(data => this.downloadCommon(data, '登録ユーザー一覧.csv'));
    },
    downloadCommon(data, filename) {
      const anchor = document.createElement('a');
      document.body.appendChild(anchor);
      // BOM付きUTF-8にして文字化け対策
      const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
      const blob = new Blob([bom, data], { type: 'text/csv' });
      const objectUrl = window.URL.createObjectURL(blob);
      anchor.href = objectUrl;
      anchor.download = filename;
      anchor.click();

      window.URL.revokeObjectURL(objectUrl);
    },
    getTotalUsers() {
      var url = `${process.env.VUE_APP_API_BASE_URL}/admin/user/total`
      axios.get(url)
        .then((result) => {
          this.total_users = result.data.total;
          this.total_users_change = result.data.day_before;
        })
        .catch(error => {
          console.error(error)
        })
    },
    getTotalProperties() {
      var url = `${process.env.VUE_APP_API_BASE_URL}/admin/property/total`
      axios.get(url)
        .then((result) => {
          this.total_properties = result.data.total;
          this.total_properties_change = result.data.day_before;
        })
        .catch(error => {
          console.error(error)
        })
    },
    logout() {
      var logoutUrl = `${process.env.VUE_APP_API_BASE_URL}/admin/logout`
      const _this = this

      axios.post(logoutUrl)
        .then(() => {
          _this.$store.dispatch('clearAdminUser')
          _this.$store.commit('setFlashSuccess', `ログアウトしました`)
          _this.$router.replace('/admin/login')
        })
        .catch(error => {
          console.log(error)
          _this.$store.commit('setFlashError', `ログアウトに失敗しました`)
        })
    }
  },

  setup() {
    const store = useStore()
    const userName = computed(() => {
      return store.state.loginAdminUser.name
    })

    return {
      userName
    }
  },
  mounted() {
    this.getTotalProperties()
    this.getTotalUsers()
  },
}
</script>

<style scoped>
.admin-menu__wrap {
  height: 100vh;
  width: 200px;
  background-color: #1C492A;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 4px 0px 10px -6px #777777;
}

.admin-menu__logo-wrap {
  margin: 30px 0 20px 0;
}

.admin-menu-overflow {
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.admin-menu-overflow::-webkit-scrollbar {
  display: none;
}

.admin-menu__items-wrap {
  font-size: 16px;
  margin-top: 30px;
}

.menu-num__wrap {
  margin-top: 100px;
  width: 170px;
  border-top: 1px solid #fff;
}

.admin-menu__icon-wrap {
  display: none;
}

.admin-menu__logo,
.admin-menu__text,
.sidePanel__text {
  cursor: pointer;
}

.admin-menu__num-wrap,
.sidePanel-num {
  padding: 0 10px;
}

.admin-menu__userName {
  font-size: 14px;
}

.admin-menu__num {
  font-size: 16px;
}

.admin-menu__num span {
  font-size: 20px;
  font-weight: 600;
}

.menu-logout__wrap {
  margin-top: 300px;
}

@media screen and (max-width:800px) {
  .admin-menu__wrap {
    height: 80px;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    padding: 0 30px;
  }

  .admin-menu__logo-wrap,
  .admin-menu__items-wrap {
    margin-top: 0;
  }

  .admin-menu__items-wrap,
  .menu-num__wrap,
  .menu-logout__wrap {
    display: none;
  }

  .admin-menu__icon-wrap {
    display: block;
  }

  .sidePanel-menu {
    padding: 20px;
  }

  .sidePanel-menu p {
    margin-bottom: 30px;
  }

  .sidePanel-logout {
    margin-top: 100px;
  }

  .sidePanel-menu .v-divider {
    border-top-width: 1px;
    border-color: #fff;
    margin-bottom: 20px;
  }

}
</style>
