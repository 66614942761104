<template>
  <div id="app">
    <FlashMessage />
    <router-view />
  </div>
</template>

<script>
import FlashMessage from '@/components/parts/FlashMessage.vue'

export default {
  components: {
    FlashMessage
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap');

#app {
  font-family: 'Noto Sans JP', sans-serif;
}

body, h1{
  margin: 0;
}
</style>
